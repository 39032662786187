import React from 'react';
import LazyLoad from 'react-lazyload';

// Assets
import ib10 from './img/ib10.png';

// Wheeler, Rawson & Co.
import poncho from './img/wheeler-rawson-and-co/poncho.jpg';
import inGame from './img/wheeler-rawson-and-co/in-game.jpg';
import revolver from './img/wheeler-rawson-and-co/revolver.jpg';
import bandana from './img/wheeler-rawson-and-co/bandana.jpg';
import clothing from './img/wheeler-rawson-and-co/clothing.jpg';
import home from './img/wheeler-rawson-and-co/home.jpg';
import tobacco from './img/wheeler-rawson-and-co/tobacco.jpg';
import tonics from './img/wheeler-rawson-and-co/tonics.jpg';
import mobileBitters from './img/wheeler-rawson-and-co/mobile/bitters.jpg';
import mobilePending from './img/wheeler-rawson-and-co/mobile/pending.jpg';

// Social Club
import scHome from './img/social-club/home.jpg';
import scFeed from './img/social-club/feed.jpg';
import scPhotos from './img/social-club/photos.jpg';
import scMobileHome from './img/social-club/mobile/home.jpg';
import scMobileFeed from './img/social-club/mobile/feed.jpg';

// RDR2
import rdr2Overview from './img/rdr2/story_overview.jpg';
import rdr2Feed from './img/rdr2/story_compendium_animals.jpg';
import rdr2Online from './img/rdr2/online_overview.jpg';
import rdr2OnlineAwards from './img/rdr2/online_awards.jpg';
import rdr2OnlineUnlocks from './img/rdr2/online_unlocks.jpg';
import rdr2MobileChapters from './img/rdr2/mobile/story_chapters.jpg';
import rdr2MobileCompendium from './img/rdr2/mobile/story_compendium_equipment.jpg';

// GTAV
import gtavCareer from './img/gtav/career_checklist.jpg';
import gtavMobileOverview from './img/gtav/mobile/career_overview.jpg';

// Wheeler, Rawson & Co.
import ponchoThumb from './img/thumbs/wheeler-rawson-and-co/poncho.jpg';
import inGameThumb from './img/thumbs/wheeler-rawson-and-co/in-game.jpg';
//import revolverThumb from './img/thumbs/wheeler-rawson-and-co/revolver.jpg';
import bandanaThumb from './img/thumbs/wheeler-rawson-and-co/bandana.jpg';
import clothingThumb from './img/thumbs/wheeler-rawson-and-co/clothing.jpg';
import homeThumb from './img/thumbs/wheeler-rawson-and-co/home.jpg';
import tobaccoThumb from './img/thumbs/wheeler-rawson-and-co/tobacco.jpg';
import tonicsThumb from './img/thumbs/wheeler-rawson-and-co/tonics.jpg';
//import mobileBittersThumb from './img/thumbs/wheeler-rawson-and-co/mobile/bitters.jpg';
//import mobilePendingThumb from './img/thumbs/wheeler-rawson-and-co/mobile/pending.jpg';

// Social Club
import scHomeThumb from './img/thumbs/social-club/home.jpg';
import scFeedThumb from './img/thumbs/social-club/feed.jpg';
import scPhotosThumb from './img/thumbs/social-club/photos.jpg';
//import scMobileHomeThumb from './img/thumbs/social-club/mobile/home.jpg';
//import scMobileFeedThumb from './img/thumbs/social-club/mobile/feed.jpg';

// RDR2
import rdr2OverviewThumb from './img/thumbs/rdr2/story_overview.jpg';
import rdr2FeedThumb from './img/thumbs/rdr2/story_compendium_animals.jpg';
import rdr2OnlineThumb from './img/thumbs/rdr2/online_overview.jpg';
import rdr2OnlineAwardsThumb from './img/thumbs/rdr2/online_awards.jpg';
import rdr2OnlineUnlocksThumb from './img/thumbs/rdr2/online_unlocks.jpg';
//import rdr2MobileChaptersThumb from './img/thumbs/rdr2/mobile/story_chapters.jpg';
//import rdr2MobileCompendiumThumb from './img/thumbs/rdr2/mobile/story_compendium_equipment.jpg';

// GTAV
import gtavCareerThumb from './img/thumbs/gtav/career_checklist.jpg';
//import gtavMobileOverviewThumb from './img/thumbs/gtav/mobile/career_overview.jpg';

// Styles
import './App.css';

function App() {

  return (
    <div className='wrap'>

      <header className='header'>
          <a href='http://iblack10.co.uk' className='ib10'>
            <img src={ib10} alt='IB10' />
          </a>
      </header>

      <section className='project'>

        <div className='container'>

          <h2>Wheeler, Rawson & Co.</h2>

          <ul className='skills'>
            <li>HTML</li>
            <li>CSS</li>
            <li>JavaScript</li>
            <li>C#</li>
            <li>Wire-framing</li>
            <li>Prototyping</li>
            <li>UX</li>
            <li>Leadership</li>
          </ul>

          <div className='cols'>
            <p>Developed to accompany the release of Red Dead Redemption 2, the Wheeler, Rawson & Co. catalogue provides players with a web-based version of the in-game catalogue.</p>

            <p>Players can browse and purchase items and have them delivered in-game. In Red Dead Online - which uses a mix of virtual currency and gold (purchaseable with real money) - all interactions with the catalogue are done in real-time using WebSockets to communicate with the game server (which I helped to prototype from the initial stages through to production).</p>

            <p>From the outset our goal was to try to remain as authentic and 'of the time' as possible while ensuring that the web-based catalogue user-friendly and intuitive to use on a modern medium.</p>
          </div>

          <a href='http://wheelerrawson.com' className='btn'>View Live Project</a>

        </div>

        <a href={revolver} className='screen'>
          <img src={revolver} alt='Wheeler, Rawson & Co. - Product - Revolver' />
        </a>
        
        <div className='container'>
          <div className='cols'>
            <p>Constantly referencing materials from the 1900s, one of the biggest challenges was to retain that sense of style and layout throughout the catalogue. I'm really proud of the outcome. A lot of experimentation and perseverance went into ensuring the catalogue remained true to the time-frame. It makes use of:
            </p>
            
            <ul className='features'>
              <li>React</li>
              <li>Redux</li>
              <li>Websockets</li>
              <li>Sass</li>
              <li>flexbox</li>
              <li>border-image</li>
              <li>background-clip: text</li>
              <li>SVGs with clip-path</li>
            </ul>

            <p>A masonry grid was used to display the product listings and I wrote a custom component which is used across the web catalogue in order to have pieces of text dynamically resize up or down to fit their containers - a technique common in print design.</p>

            <p>I was the lead front-end developer on this project and was responsible for the early prototyping stages right up to the production release. Collaboration was also a key factor and I was able to liaise with multiple teams across different departments and timezones in a fast paced environment.</p>

            <p>Further screen grabs below are taken straight from a production build:</p>
          </div>
        </div>
        
        <div className='masonry'>
          <a href={clothing} className='screen'>
            <LazyLoad offset={200} resize once>
              <img src={clothingThumb} alt='Wheeler, Rawson & Co. - Clothing' />
            </LazyLoad>
          </a>

          <a href={poncho} className='screen'>
            <LazyLoad offset={200} resize once>
                <img src={ponchoThumb} alt='Wheeler, Rawson & Co. - Product - Poncho' />
              </LazyLoad>
          </a>

          <a href={mobilePending} className='screen'>
            <LazyLoad offset={200} resize once>
              <img src={mobilePending} alt='Wheeler, Rawson & Co. - Mobile - Pending Orders' />
            </LazyLoad>
          </a>

          <a href={home} className='screen'>
            <LazyLoad offset={200} resize once>
              <img src={homeThumb} alt='Wheeler, Rawson & Co. - Home' />
            </LazyLoad>
          </a>
          
          <a href={bandana} className='screen'>
            <LazyLoad offset={200} resize once>
              <img src={bandanaThumb} alt='Wheeler, Rawson & Co. - Product - Bandana' />
            </LazyLoad>
          </a>
          
          <a href={tobacco} className='screen'>
            <LazyLoad offset={200} resize once>
              <img src={tobaccoThumb} alt='Wheeler, Rawson & Co. - Product - Tobacco' />
            </LazyLoad>
          </a>
          
          <a href={tonics} className='screen'>
            <LazyLoad offset={200} resize once>
              <img src={tonicsThumb} alt='Wheeler, Rawson & Co. - Product - Tonics' />
            </LazyLoad>
          </a>

          <a href={inGame} className='screen'>
            <LazyLoad offset={200} resize once>
              <img src={inGameThumb} alt='Wheeler, Rawson & Co. - In-game comparison' />
            </LazyLoad>
          </a>
          
          <a href={mobileBitters} className='screen'>
            <LazyLoad offset={200} resize once>
              <img src={mobileBitters} alt='Wheeler, Rawson & Co. - Mobile - Bitters' />
            </LazyLoad>
          </a>
      </div>

      </section>

      <section className='project'>

        <div className='container'>

          <h2>Red Dead Redemption 2</h2>

          <ul className='skills'>
            <li>HTML</li>
            <li>CSS</li>
            <li>JavaScript</li>
            <li>C#</li>
            <li>Wire-framing</li>
            <li>Prototyping</li>
            <li>UX</li>
            <li>Leadership</li>
          </ul>
          
          <div className='cols'>
            <p>I was the lead front-end developer for the Red Dead Redemption 2 micro-site on Rockstar Games Social Club. Integrating with multiple game-side services and liaising with multiple teams I was responsible for delivering the front-end build which allows players of RDR2 to track their game progress.</p>
          </div>

          <a href='http://socialclub.rockstargames.com/games/rdr2' className='btn'>View Live Project</a>

        </div>

        <div className='masonry'>
          <a href={rdr2OnlineUnlocks} className='screen'>
            <LazyLoad offset={200} resize once>
              <img src={rdr2OnlineUnlocksThumb} alt='Red Dead Redemption 2 - Rank Unlocks' />
            </LazyLoad>
          </a>

          <a href={rdr2OnlineAwards} className='screen'>
            <LazyLoad offset={200} resize once>
              <img src={rdr2OnlineAwardsThumb} alt='Red Dead Redemption 2 - Awards' />
            </LazyLoad>
          </a>

          <a href={rdr2Overview} className='screen'>
            <LazyLoad offset={200} resize once>
              <img src={rdr2OverviewThumb} alt='Red Dead Redemption 2 - Overview' />
            </LazyLoad>
          </a>

          <a href={rdr2MobileCompendium} className='screen'>
            <LazyLoad offset={200} resize once>
              <img src={rdr2MobileCompendium} alt='Red Dead Redemption 2 - Equipment' />
            </LazyLoad>
          </a>

          <a href={rdr2Online} className='screen'>
            <LazyLoad offset={200} resize once>
              <img src={rdr2OnlineThumb} alt='Red Dead Redemption 2 - Online Overview' />
            </LazyLoad>
          </a>

          <a href={rdr2MobileChapters} className='screen'>
            <LazyLoad offset={200} resize once>
              <img src={rdr2MobileChapters} alt='Red Dead Redemption 2 - Story' />
            </LazyLoad>
          </a>

          <a href={rdr2Feed} className='screen'>
            <LazyLoad offset={200} resize once>
              <img src={rdr2FeedThumb} alt='Red Dead Redemption 2 - Compendium' />
            </LazyLoad>
          </a>
        </div>

      </section>

      <section className='project'>

        <div className='container'>

          <h2>Rockstar Games Social Club</h2>

          <ul className='skills'>
            <li>HTML</li>
            <li>CSS</li>
            <li>JavaScript</li>
            <li>C#</li>
            <li>Wire-framing</li>
            <li>Prototyping</li>
            <li>UX</li>
            <li>Leadership</li>
          </ul>

          <div className='cols'>
            <p>I was the lead front-end developer for Rockstar Games Social Club - a portal with over 100 million members. Recently rebuilt in React from Backbone I was responsible for the delivery of the front-end from early prototypes to production.</p>
            <p>The project involved wire-framing, prototyping and coding reusable React components from scratch. An internal UI Library was created to allow us to share components across multiple different Rockstar projects.</p>
          </div>
          <a href='http://socialclub.rockstargames.com' className='btn'>View Live Project</a>

        </div>

        <div className='masonry'>
          <a href={scHome} className='screen'>
            <LazyLoad offset={200} resize once>
              <img src={scHomeThumb} alt='Rockstar Games Social Club - Home' />
            </LazyLoad>
          </a>

          <a href={scMobileHome} className='screen'>
            <LazyLoad offset={200} resize once>
              <img src={scMobileHome} alt='Rockstar Games Social Club - Mobile - Home' />
            </LazyLoad>
          </a>

          <a href={scFeed} className='screen'>
            <LazyLoad offset={200} resize once>
              <img src={scFeedThumb} alt='Rockstar Games Social Club - Feed' />
            </LazyLoad>
          </a>

          <a href={scPhotos} className='screen'>
            <LazyLoad offset={200} resize once>
              <img src={scPhotosThumb} alt='Rockstar Games Social Club - Photos' />
            </LazyLoad>
          </a>

          <a href={scMobileFeed} className='screen'>
            <LazyLoad offset={200} resize once>
              <img src={scMobileFeed} alt='Rockstar Games Social Club - Mobile - Feed' />
            </LazyLoad>
          </a>
        </div>

      </section>
      
      <section className='project'>

        <div className='container'>

          <h2>Grand Theft Auto V</h2>

          <ul className='skills'>
            <li>HTML</li>
            <li>CSS</li>
            <li>JavaScript</li>
            <li>C#</li>
          </ul>

          <p>Companion micro-site to allow players to track and view their progress.</p>

          <a href='http://socialclub.rockstargames.com/games/gtav' className='btn'>View Live Project</a>

        </div>

        <LazyLoad offset={200} resize once>
          <div className='masonry'>
            <a href={gtavCareer} className='screen'>
              <LazyLoad offset={200} resize once>
                <img src={gtavCareerThumb} alt='Grand Theft Auto V - Checklist' />
              </LazyLoad>
            </a>

            <a href={gtavMobileOverview} className='screen'>
              <LazyLoad offset={200} resize once>
                <img src={gtavMobileOverview} alt='Grand Theft Auto V - Overview' />
              </LazyLoad>
            </a>
          </div>
        </LazyLoad>

      </section>

    </div>
  );
}

export default App;